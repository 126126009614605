<template>
  <li class="mr-3">
    <component :is="inertia ? 'Link' : 'a'" class="inline-block no-underline hover:text-gray-800 hover:text-underline py-2 px-4" :href="href">
      <font-awesome-icon :icon="icon" class="mx-1" fixed-width /><slot />
    </component>
  </li>
</template>

<script>
  import { Link } from '@inertiajs/vue3';
  export default
  {
    props: ['inertia', 'href', 'icon'],
    components:
    {
      Link
    }
  }
</script>
