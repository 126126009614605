<template>
  <li class="mt-2 inline-block mr-2 md:block md:mr-0">
    <component  :is="inertia ? 'Link' : 'a'"
                :target="extern ? '_blank' : '_self'"
                :href="href"
                class="bottomLink"
                :rel="extern ? 'nofollow noopener noreferrer' : ''">
      <slot />
    </component>
  </li>
</template>

<script>
  import { Link } from '@inertiajs/vue3';
  export default
  {
    props: ['inertia', 'href', 'extern'],
    components:
    {
      Link
    }
  }
</script>
